.overview {
  h1 {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-size: 36px;
    font-weight: bold;
    color: rgb(42, 42, 42);
    font-family: 'Poppins';
  }

  h2 {
    font-size: 20px;
    font-weight: 200;
    font-family: 'Poppins';
    color: rgb(42, 42, 42);
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5rem;
    font-family: 'Poppins';
  }

  p {
    font-size: 14px;
    color: rgb(42, 42, 42);
    font-family: 'Inter';
  }

  a {
    font-size: 14px;
    color: rgb(42, 42, 42);
    font-family: 'Inter';
    text-decoration: none;
  }

  &__line {
    position: relative;
    width: 100%;
    z-index: -1;

    .left-label {
      position: absolute;
      transform: rotate(-90deg);
      font-size: 20px;
      top: 50%;
      left: -3rem;
    }

    .bottom-label {
      position: absolute;
      font-size: 20px;
      bottom: -2rem;
      left: 47%;
      transform: translateX(-50%);
    }
  }

  &__links {
    padding-left: 0;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;

    li {
      font-size: 14px;
      margin-right: 1.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-family: 'Inter';
    }

    img {
      margin-right: 0.5rem;
      width: auto;
      height: 21px;
      object-fit: contain;
    }

    span {
      white-space: nowrap;
    }
  }

  &__live-tx {
    li {
      list-style-type: disc;
      font-size: 14px;
      color: rgb(42, 42, 42);
      font-family: 'Inter';
    }

    margin-bottom: 0;
  }

  &__workflow {
    margin-bottom: 0;
    li {
      list-style-type: circle;
      font-size: 14px;
      color: rgb(42, 42, 42);
      font-family: 'Inter';
    }
  }

  &__pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > div {
      background: rgba(0, 0, 255, 0.05);
      font-size: 10px;
      padding: 3px 6px;
      min-width: 1rem;
      text-align: center;
      margin: 0 2px;
      cursor: pointer;
    }
  }

  img {
    width: 100%;
  }

  canvas {
    width: 80% !important;
    height: auto !important;
    margin-left: 4rem;
    margin-top: 3rem;
  }

  .img {
    &__architecture {
      width: 65%;
      margin-left: auto;
      margin-right: auto;
    }

    &__drl-model {
      margin-left: auto;
      margin-right: auto;
      width: 65%;
      min-width: 300px;
    }

    &__drl-model1 {
      margin-left: auto;
      margin-right: auto;
      width: 40%;
      min-width: 150px;
    }

    &__drl-metrics {
      transform: translateX(-8rem);
    }
  }

  .ledger {
    &__item {
      padding: 1rem;
      border-bottom: 1px solid #ddd;
      width: 100%;
      margin: 1rem 0;

      &:first-child {
        border-top: 1px solid #ddd;
      }

      p,
      span {
        font-size: 14px;
        margin-bottom: 0;
      }
      p {
        text-transform: capitalize;
      }

      button {
        width: 104px;
        height: 38px;
        color: white;
        border-radius: 4px;
        border: none;
        outline: none;
        font-size: 14px;
        padding: 4px;

        &:hover {
          opacity: 0.5;
        }

        &.beer {
          background-color: rgb(37, 62, 237);
        }
        &.cash {
          background-color: rgb(39, 163, 3);
        }
      }
    }

    &__wrapper {
      width: 100%;
      overflow: auto;
      padding-left: 3rem;
    }
  }

  &__filter {
    display: flex;
    margin-bottom: 0.5rem;
    cursor: pointer;
    margin-bottom: -2rem;
    margin-left: 5rem;

    &-item {
      text-align: center;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      border: 1px solid #ddd;
      margin-right: 3px;
      font-size: 14px;

      &.active {
        background: rgba(100, 100, 255);
      }
    }
  }

  &__game-model {
    position: relative;

    &__animation {
      animation: 0.5s infinite alternate fadeIn;
      animation-iteration-count: 10;
      white-space: nowrap;
    }

    &__wrapper {
      display: flex;
      align-items: cener;
      justify-content: space-between;
    }

    &__item {
      &-image {
        width: 200px;
        height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: color;
        box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.05);
        padding: 8px;

        img {
          width: 35%;
          height: auto;
        }

        p {
          width: 100%;
          text-transform: capitalize;
        }
      }
      &-amount {
        text-align: center;
        margin-top: 0.5rem;

        .beer {
          margin-bottom: 0;
          color: rgb(37, 62, 237);
        }
        .cash {
          margin-bottom: 0;
          color: rgb(39, 163, 3);
        }
      }
    }

    &__ani {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: translateY(-2rem);
      margin: 0 1rem;
      font-size: 16px;

      svg {
        width: 58px;
        height: 10px;
        margin: 1rem 0;
      }

      span {
        font-size: 12px;

        &:first-child {
          margin-bottom: -1rem;
          color: rgb(37, 62, 237);
        }

        &:last-child {
          margin-top: -1rem;
          color: rgb(39, 163, 3);
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
