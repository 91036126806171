@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800';
@import 'https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700,800';
@import 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto';
  background-color: white;
}
* {
  font-family: 'Roboto';
}

iframe {
  pointer-events: none;
}

#root {
  position: relative;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: calc((100% - 980px) * 0.5 - 100px);
  margin-right: calc((100% - 980px) * 0.5);
}
